import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { useCookies } from 'react-cookie';

import ProductRichSnippet from 'components/common/rich-snippets/product/Product';
import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import Hero from 'components/directus/compare-package-hero/ComparePackageHero';
import ComparePackageTab from 'components/directus/compare-package-tab/ComparePackageTab';
import OrderSteps from 'components/directus/order-steps/OrderSteps';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { PageProps } from 'utils/gatsby.interface';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
  const { node } = getFieldBySlug('compare-packages/scottish', data.allDirectusPage.edges);
  const [cookies] = useCookies();
  const companyName = cookies['company-name'];

  return (
    <>
      <Helmet>
        <title>{node.title}</title>
        <meta name="title" content={node.title} />
        <meta name="description" content={node.description} />
        <meta name="robots" content={node.robots} />
        <link rel="canonical" href={node.canonical} />
      </Helmet>

      <Wrapper>
        <Section top={0} bottom={0}>
          <OrderSteps currentStep={2} />
        </Section>
      </Wrapper>
      <Hero name={companyName} />
      <Wrapper>
        <Section>
          <ComparePackageTab slug="scottish" />
        </Section>
      </Wrapper>
      <ProductRichSnippet slug="scottish-package" />
    </>
  );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "compare-packages/scottish" } }) {
      edges {
        node {
          title
          slug
          description
          robots
          canonical
        }
      }
    }
  }
`;
